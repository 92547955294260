<template>
  <div class="main_right">
      <div class="Box">
        <div class="aRow">
          <!-- 词汇量 -->
          <div class="vocabulary">
            <div>
              <span>收藏总词数</span>
              <p>
                <span>{{ tableData.length }}</span>
                <span>个</span>
              </p>
            </div>
          </div>
        </div>
        <div class="testpage" style="height:calc(100% - 3vw);">
          <p>
            <span>{{'我的收藏'}}</span>
          </p>
          <el-table ref="table" class="table" :data="tableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
            <el-table-column align="center" width="80" type="index" label="序号" />
            <el-table-column prop="mc" align="center" show-overflow-tooltip label="单词" />
            <el-table-column prop="dcsy" align="center" show-overflow-tooltip label="词义" />
            <el-table-column align="center" show-overflow-tooltip label="收藏时间">
              <template slot-scope="scope">
                {{new Date(scope.row.xgrq).Format("yyyy-MM-dd hh:mm")}}
              </template>
            </el-table-column>
            <el-table-column prop="ccly" align="center" width="150" label="操作">
              <template slot-scope="scope">
                <span class="remove" @click="remove(scope.row)">移除收藏</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="btn" @click="study">开始学习</div>
      </div>
  </div>
</template>
<script>
import { myCollectionWord, deleteCollectionWord } from '@/api/student'
export default {
  data(){
    return{
      tableData: [],
    }
  },
  methods:{
    // 移除收藏
    remove(row) {
      this.$confirm('是否要将该单词取消收藏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCollectionWord({ id: row.id, scbj: true }).then(res => {
          if (res.success) {
            this.myCollectionWord()
            this.$message.success('移除成功')
          } else {
            this.$message.warning(res.resultDesc)
          }
        })
        console.log(row)
      }).catch(() => {         
      })
    },
    // 刷新表格
    myCollectionWord() {
      myCollectionWord({ xsid: this.$store.state.userInfo.yhid }).then(res => {
        if (res.success) {
          this.tableData = res.result
        } else {
          this.$message.warning(res.resultDesc)
        }
      })
    },
    // 开始学习
    study() {
      if (!this.tableData.length) {
        this.$message.warning('暂无单词，快去收藏吧~')
        return false
      }
      let list = []
      this.tableData.forEach(ele => {
        list.push(ele.words)
      })
      sessionStorage.setItem('words', JSON.stringify(list))
      this.$router.replace({ name: 'wordcontent', query: { source: 'collect' } })
    }
  },
  created(){
    this.myCollectionWord()
  }
}
</script>
<style lang="scss" scoped>
.main_right{
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .Box{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 2%;
    box-sizing: border-box;
    position: relative;
  }
  .vocabulary{
    width: 30%;
    height: 3vw;
    float: left;
    div{
      width: 14vw;
      height: 3vw;
      background: #F1F8FF;
      border-radius: 10px;
      line-height: 3vw;
      box-sizing: border-box;
      padding: 0 3%;
      color: #333;
      span{
        display: block;
      }
      span:nth-child(1){
        // display: block;
        font-size: 1.1vw;
        float: left;
      }
      p{
        float: right;
        span:nth-child(1){
          font-size: 1.5vw;
          color: #409EFF;
          font-weight: 600;
          float: left;
          margin-right: 1.5vw;
        }
        span:nth-child(2){
          font-size: 1vw;
          float: left;
        }
      }
    }
  }
  .testpage{
    width: 100%;
    position: relative;
    p:nth-child(1){
      width: 100%;
      height: 2.5vw;
      text-align: center;
      position: relative;
      color: #333;
      font-size: 1.5vw;
      line-height: 2.5vw;
    }
  }
  .table{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4vw;
  top: 4vw;
}
  .aRow{
    width: 100%;
    height: 3vw;
  }
  .aRow:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear:both;
    visibility: hidden;
  }
  .btn{
    width: 7vw;
    height: 2.5vw;
    color: #fff;
    background: #5990FF;
    font-size: 1.1vw;
    text-align: center;
    line-height: 2.5vw;
    border-radius: 32px;
    position: absolute;
    bottom: 2vw;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .remove{
    width: 5vw;
    height: 2vw;
    font-size: .8vw;
    background: #E0EAFF;
    border-radius: 21px;
    color: #5990FF;
    text-align: center;
    line-height: 2vw;
    display: inline-block;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.el-table{
  font-size: .9vw;
}
</style>